@import url(https://fonts.googleapis.com/css2?family=Inconsolata&family=Source+Sans+Pro:wght@300;900&display=swap);
body{margin:0;font-family:"Nunito",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;box-sizing:border-box;overflow:hidden}*{box-sizing:inherit;z-index:inherit}.fa-scholar{content:url(/static/media/google-scholar.d5f612f2.svg);height:1em;width:auto}
:root{--app-height: 100%}html,body{margin:0;padding:0;height:100vh;height:100%;height:var(--app-height);-webkit-user-select:none;user-select:none}.App_stage__1Bspp{display:flex;flex-direction:row;width:100vw}main{width:100vw;height:100%;height:100%;height:var(--app-height);scroll-behavior:smooth;overflow-y:scroll}section{z-index:1;display:flex;align-items:center;justify-content:center}section.App_intro__dm7rj{height:100vh;height:100%;height:var(--app-height);display:flex;flex-direction:column}canvas{position:absolute;top:0;z-index:-1}.App_main__uN9ca{position:relative;margin:auto auto 5vh auto;min-width:20em;max-width:60em;display:flex;flex-direction:column}@media(max-height: 418px){.App_main__uN9ca{margin:20vh auto 0 auto}}@media(max-height: 418px)and (orientation: landscape){.App_main__uN9ca{width:60vw}}.App_main__uN9ca.App_with_pp__3I3y4::before{content:"";background-image:url(/static/media/pp.3b8c4b34.jpg);background-position:center center;background-size:contain;border-radius:100%;border:.2em solid #fff;width:20vmin;height:20vmin;position:absolute;z-index:2;top:calc(-15vmin - .2em);left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%)}@media(max-height: 418px){.App_main__uN9ca.App_with_pp__3I3y4::before{top:5vmin;left:calc(100% - 13vmin - .2em)}}.App_main__uN9ca .App_intro_content__14Lwa{margin:1vmax 4vmin 1vmax 4vmin;padding:1vmax 1vmin;border-radius:.8rem;font-family:"Inconsolata",monospace;color:#fff;background-color:rgba(0,0,15,.9)}@media only screen and (min-width: 418px){.App_main__uN9ca .App_intro_content__14Lwa{top:5vmin}}.App_main__uN9ca .App_header__2L3DO{display:flex;align-items:center}.App_main__uN9ca .App_header__2L3DO span{flex:1 1;font-weight:900;letter-spacing:-0.035em;font-family:"Source Sans Pro",sans-serif}.App_main__uN9ca .App_header__2L3DO span{font-size:1.3rem}@media screen and (min-width: 20em){.App_main__uN9ca .App_header__2L3DO span{font-size:calc(
					1.3rem +
						1.7 *
						((100vw - 20em) / 40)
				)}}@media screen and (min-width: 60em){.App_main__uN9ca .App_header__2L3DO span{font-size:3rem}}.App_main__uN9ca .App_header__2L3DO div.App_icons__vmQyE{display:flex;flex-direction:row;justify-content:center;align-items:center;max-width:33%}.App_main__uN9ca .App_header__2L3DO div.App_icons__vmQyE{--scale:1rem}@media screen and (min-width: 20em){.App_main__uN9ca .App_header__2L3DO div.App_icons__vmQyE{--scale:calc(
					1rem +
						0.4 *
						((100vw - 20em) / 40)
				)}}@media screen and (min-width: 60em){.App_main__uN9ca .App_header__2L3DO div.App_icons__vmQyE{--scale:1.4rem}}.App_main__uN9ca .App_header__2L3DO div.App_icons__vmQyE div{flex:1 1;display:flex;flex-direction:row;justify-content:flex-end;flex-wrap:wrap;max-height:calc(var(--scale)*1.5);overflow:hidden}.App_main__uN9ca .App_header__2L3DO div.App_icons__vmQyE a{--gap: calc(var(--scale) * 0.5);margin-left:var(--gap);width:calc(var(--scale)*1.5);height:calc(var(--scale)*1.5);border-radius:100%;border:2px solid rgba(255,255,255,.5019607843);display:flex;justify-content:center;align-items:center;text-decoration:none;color:rgba(255,255,255,.5019607843);transition:all .1s ease-in-out}.App_main__uN9ca .App_header__2L3DO div.App_icons__vmQyE a:hover{color:#fff;border-color:#fff}@media(max-height: 418px)and (orientation: landscape){.App_main__uN9ca .App_header__2L3DO div.App_icons__vmQyE{display:none}}.App_main__uN9ca .App_contact_details__1Vqyd{padding:.8rem 0;border-top:1px dashed #fff;border-bottom:1px dashed #fff}.App_main__uN9ca .App_contact_details__1Vqyd{font-size:.8rem}@media screen and (min-width: 20em){.App_main__uN9ca .App_contact_details__1Vqyd{font-size:calc(
					0.8rem +
						0.2 *
						((100vw - 20em) / 40)
				)}}@media screen and (min-width: 60em){.App_main__uN9ca .App_contact_details__1Vqyd{font-size:1rem}}.App_main__uN9ca .App_contact_details__1Vqyd h3{margin-top:0}.App_main__uN9ca .App_contact_details__1Vqyd a{color:#fff;text-decoration:none}.App_main__uN9ca .App_contact_details__1Vqyd a:hover{color:#00d18b}.App_main__uN9ca .App_title__2oBiX{padding:0px 0 8px 0;color:#888}.App_main__uN9ca .App_title__2oBiX{font-size:.8rem}@media screen and (min-width: 20em){.App_main__uN9ca .App_title__2oBiX{font-size:calc(
					0.8rem +
						0.2 *
						((100vw - 20em) / 40)
				)}}@media screen and (min-width: 60em){.App_main__uN9ca .App_title__2oBiX{font-size:1rem}}.App_main__uN9ca .App_title__2oBiX td{font-style:italic}.App_main__uN9ca p{margin:8px 0 0 0;white-space:pre-wrap}.App_main__uN9ca p{font-size:.8rem}@media screen and (min-width: 20em){.App_main__uN9ca p{font-size:calc(
					0.8rem +
						0.2 *
						((100vw - 20em) / 40)
				)}}@media screen and (min-width: 60em){.App_main__uN9ca p{font-size:1rem}}@media(max-height: 418px)and (orientation: landscape){.App_main__uN9ca p{display:none}}.App_nav_buttons__2DpaD{display:flex;flex-direction:row;justify-content:center;width:100vw;min-width:20em;max-width:60em;padding:0 5vmin}@media(max-height: 418px)and (orientation: landscape){.App_nav_buttons__2DpaD{width:60vw}}.App_nav_buttons__2DpaD div{z-index:1;height:8.5em;flex-shrink:1;padding-top:16px;transition:opacity .75s ease,-webkit-transform .75s ease;transition:transform .75s ease,opacity .75s ease;transition:transform .75s ease,opacity .75s ease,-webkit-transform .75s ease;transition-delay:3.5s;-webkit-transform:translateY(0);transform:translateY(0);border:none;outline:0;color:#fff;opacity:1;text-align:center;text-transform:uppercase;font-size:.8em;text-decoration:none;letter-spacing:.225em;cursor:pointer}.App_nav_buttons__2DpaD div::after{position:absolute;display:block;height:1.5em;width:1.5em;left:50%;bottom:40%;margin:0 0 0 -0.75em;content:"";background-image:url(/static/media/arrow.1cb7a38f.svg);background-position:center;background-repeat:no-repeat;background-size:contain}.App_nav_buttons__2DpaD div.App_left__1ks1Q::after{-webkit-transform:rotate(90deg);transform:rotate(90deg)}.App_nav_buttons__2DpaD div.App_right__1V0tl::after{-webkit-transform:rotate(-90deg);transform:rotate(-90deg)}section.App_full__2pZMi{color:#fff;min-width:320px;max-width:768px;margin:0 auto}footer{font-family:"Inconsolata",monospace;color:#fff;text-align:center;padding:5vh 0}
.CV_heading__2np93{margin-top:32px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;color:#fff;font-family:"Source Sans Pro",sans-serif;font-weight:900;text-transform:capitalize}.CV_heading__2np93{font-size:1em}@media screen and (min-width: 23em){.CV_heading__2np93{font-size:calc(
					1em +
						0.2 *
						((100vw - 23em) / 25)
				)}}@media screen and (min-width: 48em){.CV_heading__2np93{font-size:1.2em}}.CV_heading__2np93::before{content:"˧ "}.CV_container__3DI70{display:flex;flex-direction:column;width:100%;padding:0 5vmin;border-radius:.8rem;background-color:rgba(0,0,15,.9);font-family:"Inconsolata",monospace}.CV_item__3ezpu{margin:16px 0;min-height:5vh;display:flex;flex-direction:row}.CV_item__3ezpu .CV_logo__ibQZP,.CV_item__3ezpu .CV_logo__ibQZP img{align-self:flex-start}.CV_item__3ezpu .CV_logo__ibQZP,.CV_item__3ezpu .CV_logo__ibQZP img{min-width:2.4em;max-width:2.4em}@media screen and (min-width: 23em){.CV_item__3ezpu .CV_logo__ibQZP,.CV_item__3ezpu .CV_logo__ibQZP img{min-width:calc(
					2.4em +
						2.4 *
						((100vw - 23em) / 25)
				);max-width:calc(
					2.4em +
						2.4 *
						((100vw - 23em) / 25)
				)}}@media screen and (min-width: 48em){.CV_item__3ezpu .CV_logo__ibQZP,.CV_item__3ezpu .CV_logo__ibQZP img{min-width:4.8em;max-width:4.8em}}.CV_item__3ezpu .CV_logo__ibQZP,.CV_item__3ezpu .CV_logo__ibQZP img{height:2.4em}@media screen and (min-width: 23em){.CV_item__3ezpu .CV_logo__ibQZP,.CV_item__3ezpu .CV_logo__ibQZP img{height:calc(
					2.4em +
						2.4 *
						((100vw - 23em) / 25)
				)}}@media screen and (min-width: 48em){.CV_item__3ezpu .CV_logo__ibQZP,.CV_item__3ezpu .CV_logo__ibQZP img{height:4.8em}}.CV_item__3ezpu .CV_logo__ibQZP img{background-color:#fff;object-fit:contain;border-radius:16px}.CV_item__3ezpu .CV_content__1Iva7{flex-grow:1;display:flex;flex-direction:column;justify-content:center;min-width:0;margin:0 2vmin}.CV_item__3ezpu .CV_content__1Iva7 div{display:flex;justify-content:space-between;align-items:center;color:#fff}.CV_item__3ezpu .CV_content__1Iva7 div{font-size:.8em}@media screen and (min-width: 23em){.CV_item__3ezpu .CV_content__1Iva7 div{font-size:calc(
					0.8em +
						0.3 *
						((100vw - 23em) / 25)
				)}}@media screen and (min-width: 48em){.CV_item__3ezpu .CV_content__1Iva7 div{font-size:1.1em}}.CV_item__3ezpu .CV_content__1Iva7 div a{color:inherit;text-decoration:none;flex-grow:1;overflow:hidden;text-overflow:ellipsis}.CV_item__3ezpu .CV_content__1Iva7 div a.CV_has_link__1nxTt{transition:all 500ms}.CV_item__3ezpu .CV_content__1Iva7 div a.CV_has_link__1nxTt:hover{color:#0da;text-decoration:underline}.CV_item__3ezpu .CV_content__1Iva7 div span{white-space:nowrap;margin-left:2em}.CV_item__3ezpu .CV_content__1Iva7 .CV_sub__3tXE0{color:#999}.CV_item__3ezpu .CV_content__1Iva7 .CV_sub__3tXE0{font-size:.72em}@media screen and (min-width: 23em){.CV_item__3ezpu .CV_content__1Iva7 .CV_sub__3tXE0{font-size:calc(
					0.72em +
						0.27 *
						((100vw - 23em) / 25)
				)}}@media screen and (min-width: 48em){.CV_item__3ezpu .CV_content__1Iva7 .CV_sub__3tXE0{font-size:.99em}}.CV_item__3ezpu .CV_content__1Iva7 .CV_sub__3tXE0 a{color:inherit;text-decoration:none;flex-grow:1;overflow:hidden;text-overflow:ellipsis}.CV_item__3ezpu .CV_content__1Iva7 .CV_sub_content__3nWhQ{margin-top:.2rem;color:#e6e6e6}.CV_item__3ezpu .CV_content__1Iva7 .CV_sub_content__3nWhQ{font-size:.64em}@media screen and (min-width: 23em){.CV_item__3ezpu .CV_content__1Iva7 .CV_sub_content__3nWhQ{font-size:calc(
					0.64em +
						0.24 *
						((100vw - 23em) / 25)
				)}}@media screen and (min-width: 48em){.CV_item__3ezpu .CV_content__1Iva7 .CV_sub_content__3nWhQ{font-size:.88em}}.CV_item__3ezpu .CV_content__1Iva7 .CV_sub_content__3nWhQ *{white-space:pre-wrap;margin:0;padding:0}.CV_item__3ezpu .CV_content__1Iva7 .CV_sub_content__3nWhQ ul{padding:0 1em}
