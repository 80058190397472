@import "../mixins/fluid";

$bg-color-dark: rgba(0, 0, 15, 0.9);
$bg-color-light: #eee;

$minScreen: em(368);
$maxScreen: em(768);

.heading {
	margin-top: 32px;
	width: fit-content;

	color: #fff;
	// @media (prefers-color-scheme: light) {
	// 	color: #000;
	// }

	font-family: "Source Sans Pro", sans-serif;
	font-weight: 900;
	text-transform: capitalize;

	@include fluid-type(font-size, $minScreen, $maxScreen, 1em, 1.2em);

	&::before {
		content: "\02E7  ";
	}
}

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 5vmin;
	border-radius: 0.8rem;
	background-color: $bg-color-dark;

	// @media (prefers-color-scheme: light) {
	// 	background-color: $bg-color-light;
	// }

	font-family: "Inconsolata", monospace;
}

.item {
	margin: 16px 0;
	min-height: 5vh;

	display: flex;
	flex-direction: row;

	.logo {
		&,
		img {
			$mult: 0.6;
			@include fluid-type(
				(min-width, max-width),
				$minScreen,
				$maxScreen,
				em(64 * $mult),
				em(128 * $mult)
			);
			@include fluid-type(
				height,
				$minScreen,
				$maxScreen,
				em(64 * $mult),
				em(128 * $mult)
			);
			align-self: flex-start;
		}

		img {
			background-color: #fff;
			object-fit: contain;
			border-radius: 16px;
		}
	}

	.content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-width: 0;
		margin: 0 2vmin;

		$minSize: 0.8em;
		$maxSize: 1.1em;

		div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include fluid-type(
				font-size,
				$minScreen,
				$maxScreen,
				$minSize,
				$maxSize
			);

			color: #fff;
			// @media (prefers-color-scheme: light) {
			// 	color: #000;
			// }

			a {
				color: inherit;
				text-decoration: none;
				flex-grow: 1;

				overflow: hidden;
				text-overflow: ellipsis;
			}

			a.has_link {
				transition: all 500ms;

				&:hover {
					color: #00ddaa;
					text-decoration: underline;

					// @media (prefers-color-scheme: light) {
					// 	color: #00ad85;
					// }
				}
			}

			span {
				white-space: nowrap;
				margin-left: 2em;
			}
		}

		.sub {
			@include fluid-type(
				font-size,
				$minScreen,
				$maxScreen,
				$minSize * 0.9,
				$maxSize * 0.9
			);

			color: darken($color: #fff, $amount: 40%);
			// @media (prefers-color-scheme: light) {
			// 	color: lighten($color: #000, $amount: 40%);
			// }

			a {
				color: inherit;
				text-decoration: none;
				flex-grow: 1;

				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.sub_content {
			margin-top: 0.2rem;
			@include fluid-type(
				font-size,
				$minScreen,
				$maxScreen,
				$minSize * 0.8,
				$maxSize * 0.8
			);

			color: darken($color: #fff, $amount: 10%);
			// @media (prefers-color-scheme: light) {
			// 	color: lighten($color: #000, $amount: 10%);
			// }

			* {
				white-space: pre-wrap;
				margin: 0;
				padding: 0;
			}

			ul {
				padding: 0 1em;
			}
		}
	}
}
