body {
	margin: 0;
	font-family: "Nunito", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	box-sizing: border-box;
	overflow: hidden;
}

* {
	box-sizing: inherit;
	z-index: inherit;
}

.fa-scholar {
	content: url("img/google-scholar.svg");
	height: 1em;
	width: auto;
}
