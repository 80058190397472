@import url("https://fonts.googleapis.com/css2?family=Inconsolata&family=Source+Sans+Pro:wght@300;900&display=swap");
@import "./mixins/fluid";

$bg-color-dark: rgba(0, 0, 15, 0.9);
$bg-color-light: #eee;
$minScreen: em(320);
$maxScreen: em(960);

:root {
	--app-height: 100%;
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100vh;
	height: var(--app-height);
	user-select: none;
}

.stage {
	display: flex;
	flex-direction: row;
	width: 100vw;
}

main {
	width: 100vw;
	height: 100%;
	height: var(--app-height);
	scroll-behavior: smooth;
	overflow-y: scroll;
}

section {
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

section.intro {
	height: 100vh;
	height: var(--app-height);
	display: flex;
	flex-direction: column;
}

canvas {
	position: absolute;
	top: 0;
	z-index: -1;
}

.main {
	// Position
	position: relative;
	margin: auto auto 5vh auto;
	@media (max-height: 418px) {
		margin: 20vh auto 0 auto;
	}

	// Area
	min-width: $minScreen;
	max-width: $maxScreen;
	@media (max-height: 418px) and (orientation: landscape) {
		width: 60vw;
	}

	// Layout
	display: flex;
	flex-direction: column;

	// Profile
	&.with_pp {
		&::before {
			// Content
			content: "";
			background-image: url("img/pp.jpg");
			background-position: center center;
			background-size: contain;

			// Border
			border-radius: 100%;
			border: 0.2em solid white;

			// @media (prefers-color-scheme: light) {
			// 	border-color: #000;
			// }

			// Size
			width: 20vmin;
			height: 20vmin;

			// Layout
			position: absolute;
			z-index: 2;
			top: calc(-15vmin - 0.2em);
			left: 50%;
			transform: translateX(-50%);

			@media (max-height: 418px) {
				top: 5vmin;
				left: calc(100% - 13vmin - 0.2em);
			}
		}
	}
}

.main .intro_content {
	// Size
	margin: 1vmax 4vmin 1vmax 4vmin;
	padding: 1vmax 1vmin;
	border-radius: 0.8rem;

	font-family: "Inconsolata", monospace;

	@media only screen and (min-width: 418px) {
		// Layout
		top: 5vmin;
	}

	// Colors
	color: #fff;
	background-color: $bg-color-dark;

	// @media (prefers-color-scheme: light) {
	// 	color: #000;
	// 	background-color: $bg-color-light;
	// }
}

.main .header {
	display: flex;
	align-items: center;

	span {
		flex: 1;
		font-weight: 900;
		letter-spacing: -0.035em;
		font-family: "Source Sans Pro", sans-serif;
		@include fluid-type(font-size, $minScreen, $maxScreen, 1.3rem, 3rem);
	}

	div.icons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		max-width: 33%;
		@include fluid-type(--scale, $minScreen, $maxScreen, 1rem, 1.4rem);

		div {
			flex: 1;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			flex-wrap: wrap;
			max-height: calc(var(--scale) * 1.5);
			overflow: hidden;
		}

		a {
			--gap: calc(var(--scale) * 0.5);

			margin-left: var(--gap);

			width: calc(var(--scale) * 1.5);
			height: calc(var(--scale) * 1.5);

			border-radius: 100%;
			border: 2px solid #ffffff80;

			display: flex;
			justify-content: center;
			align-items: center;

			text-decoration: none;
			color: #ffffff80;

			transition: all 0.1s ease-in-out;

			&:hover {
				color: #fff;
				border-color: #fff;
			}

			// @media (prefers-color-scheme: light) {
			// 	border-color: #00000080;
			// 	color: #00000080;

			// 	&:hover {
			// 		color: #000;
			// 		border-color: #000;
			// 	}
			// }
		}

		@media (max-height: 418px) and (orientation: landscape) {
			display: none;
		}
	}
}

.main .contact_details {
	padding: 0.8rem 0;

	border-top: 1px dashed #fff;
	border-bottom: 1px dashed #fff;

	@include fluid-type(font-size, $minScreen, $maxScreen, 0.8rem, 1rem);

	h3 {
		margin-top: 0;
	}

	a {
		color: white;
		text-decoration: none;

		&:hover {
			color: rgb(0, 209, 139);
		}
	}
}

.main .title {
	padding: 0px 0 8px 0;
	@include fluid-type(font-size, $minScreen, $maxScreen, 0.8rem, 1rem);

	td {
		font-style: italic;
	}

	// Colors
	color: #888;

	// @media (prefers-color-scheme: light) {
	// 	color: #00000080;
	// }
}

.main p {
	margin: 8px 0 0 0;
	white-space: pre-wrap;
	@include fluid-type(font-size, $minScreen, $maxScreen, 0.8rem, 1rem);

	@media (max-height: 418px) and (orientation: landscape) {
		display: none;
	}
}

.nav_buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100vw;

	// Area
	min-width: $minScreen;
	max-width: $maxScreen;
	padding: 0 5vmin;
	@media (max-height: 418px) and (orientation: landscape) {
		width: 60vw;
	}

	div {
		z-index: 1;
		height: 8.5em;
		flex-shrink: 1;
		padding-top: 16px;

		transition: transform 0.75s ease, opacity 0.75s ease;
		transition-delay: 3.5s;
		transform: translateY(0);

		border: none;
		outline: 0;
		color: white;
		opacity: 1;
		text-align: center;
		text-transform: uppercase;
		font-size: 0.8em;
		text-decoration: none;
		letter-spacing: 0.225em;

		cursor: pointer;

		&::after {
			position: absolute;
			display: block;
			height: 1.5em;
			width: 1.5em;
			left: 50%;
			bottom: 40%;
			margin: 0 0 0 -0.75em;
			content: "";

			background-image: url("img/arrow.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}

		&.left {
			&::after {
				transform: rotate(90deg);
			}
		}

		&.right {
			&::after {
				transform: rotate(-90deg);
			}
		}
	}
}

section.full {
	color: white;
	min-width: 320px;
	max-width: 768px;
	margin: 0 auto;
}

footer {
	font-family: "Inconsolata", monospace;
	color: white;
	text-align: center;
	padding: 5vh 0;
}
